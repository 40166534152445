<template>
  <div class="content_box">
    <div class="head">
      <!-- <img class="logo_img" src="@/assets/LOGO/齐齐哈尔市.jpg" alt=""> -->
      <span>邯郸市市气象矩阵</span>
    </div>
    <ul class="matrix_box">
      <li v-for="item of list" :key="item.name" @click="gotoGZH(item)">
        <div class="logo_item">
          <img :src="require(`@/assets/LOGO/${item.name}.jpg`)" alt="">
          <span>{{ item.name }}</span>
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        // { name: "齐齐哈尔市", type: '订阅号', game: '齐齐哈尔气象', next: 'Mzg4NTY1MjU1NQ==' },
        // { name: "龙江县", type: "订阅号", game: '龙江县气象服务', next: 'MzkyMDQyNTM5Ng==' },
        // { name: "甘南县", type: "服务号", game: '甘南县气象服务', next: 'Mzk0NDI2MjEwNQ==' },
        // { name: "克山县", type: "订阅号", game: '克山气象', next: 'MzkzMDI4OTI2Ng==' },
        // { name: "富裕县", type: "订阅号", game: '富裕县气象服务', next: '	MzkyNDI4MDQxMA==' },
        // { name: "泰来县", type: "订阅号", game: '泰来县气象服务', next: '	Mzg2MTY0OTUxNQ==' },
        // { name: "讷河市", type: "服务号", game: '讷河气象服务', next: 'Mzg2MzY3NDY3Mg==' },
        // { name: "依安县", type: "服务号", game: '依安气象', next: 'Mzk0ODQyNjYwOA==' },
        // { name: "克东县", type: "服务号", game: '克东县气象台', next: 'Mzk0MDQyNjgzMA==' },
      ]
    }
  },
  methods: {
    gotoGZH(item) {
      // window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=' + item.next;
      window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=' + item.next + "#wechat_redirect";
      console.log(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.content_box {
  overflow: hidden;
  background-color: #F0F0F0;
}

.head {
  width: 100%;
  height: 150px;
  // background-color: aqua;
  background-image: url('../assets/LOGO/GZHbg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: scale(1.5);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  .logo_img {
    width: 50px;
  }

  >span {
    transform: translateY(20px);
    color: #FFF;
  }
}

.matrix_box {
  padding:0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 30%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff;
    display: inline-block;

    div.logo_item {
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
      }

      span {
        font-size: 13px;
        text-align: center;
        color: #333;
        line-height: 30px;
      }
    }




  }
}
</style>