// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/LOGO/GZHbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".content_box[data-v-1314c7cc]{overflow:hidden;background-color:#f0f0f0}.head[data-v-1314c7cc]{width:100%;height:.2rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% 100%;-webkit-transform:scale(1.5);transform:scale(1.5);border-bottom-left-radius:100%;border-bottom-right-radius:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;margin-bottom:.10667rem}.head .logo_img[data-v-1314c7cc]{width:.06667rem}.head>span[data-v-1314c7cc]{-webkit-transform:translateY(.02667rem);transform:translateY(.02667rem);color:#fff}.matrix_box[data-v-1314c7cc]{padding:0 .02667rem;box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.matrix_box li[data-v-1314c7cc]{width:30%;margin-bottom:.01333rem;border-radius:.01333rem;background-color:#fff;display:inline-block}.matrix_box li div.logo_item[data-v-1314c7cc]{height:.12rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.matrix_box li div.logo_item img[data-v-1314c7cc]{width:.05333rem}.matrix_box li div.logo_item span[data-v-1314c7cc]{font-size:.01733rem;text-align:center;color:#333;line-height:.04rem}", ""]);
// Exports
module.exports = exports;
